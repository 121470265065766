// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../../../device';

// Styles
const Container = styled.section`
  border-top: ${props => (props.isAbout ? 'none' : '0.0625rem solid #C6C8CC')};
  border-bottom: ${props =>
    props.isLast || props.isBorder ? '0.0625rem solid #C6C8CC' : 'none'};
  padding: ${props =>
    props.padding
      ? props.padding
      : props.containerPaddingDesk
      ? props.containerPaddingDesk
      : props.isPediatrico
      ? '4.375rem 0 !important'
      : props.isPadding
      ? '0 0 4.125rem 0'
      : props.isCDICardio
      ? '3.125rem 0 4.375rem'
      : props.isShort
      ? '3.125rem 0'
      : props.isPatologica && !props.isBackground
      ? '1.875rem 1.875rem 0'
      : props.isPatologica
      ? '1.875rem'
      : props.isPaddingImunization || '4.375rem 0'};
  display: ${props => (props.isShowing ? 'grid' : 'none')};
  width: ${props =>
    props.containerWidth
      ? props.containerWidth
      : props.width
      ? props.width
      : props.isIncreasingContainerWidth
      ? '70%'
      : props.isPediatrico
      ? '55%'
      : props.isJustCards
      ? '50%'
      : props.isPatologica && props.isBackground
      ? '58%'
      : props.isPatologica && !props.isBackground
      ? '45%'
      : props.isCIGI
      ? '65%'
      : 'auto'};
  background: ${props =>
    props.isPatologica && !props.isBackground ? '#f4f5f7' : '#ffff'};
  margin-top: ${props => props.marginTop};
  margin-bottom: 2px;
  margin-right: ${props =>
    props.noMarginRightPage
      ? '0'
      : props.isPatologica && props.isBackground && '8.125rem'};
  margin-left: ${props =>
    props.marginLeft
      ? props.marginLeft
      : props.isPatologica && !props.isBackground && '1rem'};
  border-radius: ${props => props.isPatologica && !props.isBackground && '8px'};
  grid-template-columns: ${props =>
    props.isImunization ? '2fr 2fr' : props.gridColumns};
  grid-template-rows: ${props => props.gridRows};
  height: ${props =>
    props.containerHeight || (props.isPatologica && 'fit-content')};

  img {
    margin: ${props => props.marginImage};
  }

  p {
    strong {
      display: ${props =>
        props.isBackground
          ? 'inherit'
          : props.doNotApplyDisplayBlock
          ? 'initial'
          : 'block'};
    }
  }

  ${props =>
    !props.isAddressAndTimes &&
    `
    br {
      display: ${props => (props.doNotIgnoreTagBr ? 'initial' : 'none')};
    }
  `}

  a {
    color: #45a7df;
    :visited {
      color: #45a7df;
    }
  }

  ${props =>
    props.isImunization ||
    (props.isGeriatric &&
      `
      ul{          
        li{
          width: ${props.isWidthText || '100%'};
          margin-bottom: 2.375rem !important;
          line-height: 1.625rem !important;

            @media ${device.laptop} {
            width: 100%;
            margin-bottom: 1.375rem !important;

            :last-child {
              margin-bottom: 0 !important;
            }
          }

          :last-child {
            padding: ${props.isImgImunization && '2.1875rem'};
            background-color: ${props.isImgImunization && '#FAFAFA'};

            @media ${device.laptop} {
              padding: ${props.isImgImunization && '1.25rem'};
              margin-top: ${props.isImgImunization && '0.5rem'};
            }
          }

          img{
            margin-left: ${props.isImgImunization ? '0' : '2rem'};
            margin-top: ${props.isImgImunization ? '0' : '2.375rem'};
            width: ${props.isImgImunization && '100%'};

            @media ${device.laptop} {
              
              margin-left: ${props.isImgImunization && '0'};
              margin-top: ${props.isImgImunization && '0'};
              height: ${props.isImgImunization && '100%'};
              max-height: ${props.isImgImunization && '50rem'};

            }
          }

          p{
            position: relative;
            
            strong{
              margin-left: 2rem;
            }

            a:nth-child(4){
              margin-left: 2rem;
            }

            >img{
              position: absolute;
              top: 1rem;
              margin: 0 !important;
            }
          }

        }
      }
    `)}

  :first-child {
    h2 {
      @media ${device.laptop} {
        margin: ${props => props.isPatologica && '0 0 20px'};
      }
      @media ${device.mobile} {
        margin: ${props => props.isPatologica && '30px 0 20px'};
      }

      + p {
        strong {
          display: inline-table;
        }
      }
    }
    p {
      width: ${props => props.isCIGI && '87%'};
    }
  }
  :nth-child(1) {
    p {
      strong {
        display: inline-table;
      }
    }
  }

  p:nth-child(5) {
    color: ${props =>
      props.isPatologica && props.colorText
        ? '#231f20'
        : props.isPatologica && 'black'};
    line-height: ${props =>
      props.isPatologica && props.colorText
        ? '1.625rem'
        : props.isPatologica && '2.5rem'};
  }

  p:last-child {
    margin-bottom: 0;
    min-width: ${props =>
      props.isJustCards ? '70%' : props.isPatologica && '100%'};
    width: ${props => props.isPatologica && '100%'};

    @media ${device.mobile} {
      width: ${props => props.isJustCards && '100% !important'};
    }
  }

  @media ${device.laptop} {
    width: ${props =>
      props.isPatologica && !props.isBackground
        ? '100%'
        : props.containerWidthLap && props.containerWidthLap};
    display: ${props => (props.isOthersUnitsMobile ? 'none' : 'grid')};
    border-top: ${props =>
      props.isAboutMobile ? '0.063rem solid #C6C8CC' : 'none'};
    padding: ${props =>
      props.isPediatrico
        ? '1.25rem 0 1.875rem !important'
        : props.isPatologica && !props.isBackground
        ? '1rem'
        : props.isImunization
        ? '1.375rem 0'
        : '1.875rem 0'};
    margin: ${props => props.isPediatrico && '1.875rem 0'};
    border-top: ${props =>
      props.isPatologica
        ? 'none !important'
        : props.isPediatrico
        ? '1px solid #C6C8CC'
        : props.containerBorderTopLap && props.containerBorderTopLap};
    border-bottom: ${props =>
      props.isPediatrico || (props.isImunization && '1px solid #C6C8CC')};
    margin-top: ${props => props.isPatologica && !props.isBackground && '0'};
    margin-left: ${props => props.isPatologica && !props.isBackground && '0'};
    display: ${props => props.isImunization && 'flex'};
    flex-direction: ${props => props.isImunization && 'column'};

    :last-child {
      border-bottom: none;
    }
  }

  @media ${device.tablet} {
    ${props =>
      props.isJustCards &&
      `
      width: 100%;
    `}
  }

  > h2 {
    width: ${props => props.isWidthTitle};
    font-weight: normal;
    font-size: ${props =>
      !props.isBackground && props.isPatologica ? '1.25rem' : '1.5rem'};
    line-height: ${props =>
      props.isPatologica || props.isImunization ? '1.875rem' : '1.375rem'};
    letter-spacing: 0px;
    color: #231f20;
    margin-bottom: ${props =>
      props.isShort || props.isImunization
        ? '1.875rem'
        : props.isAddressAndTimes
        ? '2.125rem'
        : props.isMarginBottomTextH2
        ? '1.8rem'
        : '3.125rem'};

    @media ${device.laptop} {
      font-size: ${props => props.containerFontSizeLap};
      margin: ${props => props.isPatologica && '1rem 0 1.25rem'};
      margin-bottom: ${props => props.isCDICardio && '1.875rem'};
      line-height: ${props => props.isPatologica && '1.375rem'};
      width: ${props => props.isImunization && '100%'};
    }

    @media ${device.tablet} {
      font-size: 1.125rem;
    }
  }
  > h2:nth-child(5) {
    margin-top: ${props => (props.isMarginBottomTextH2 ? '1.875rem' : '0')};
  }

  > h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;

    @media ${device.mobile} {
      font-size: 0.875rem;
    }
  }

  > p {
    margin-bottom: 1.875rem;
    width: ${props => props.isJustCards && '70%'};
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;

    :nth-child(4) {
      margin-bottom: ${props =>
        props.containerPChild4MarginBottom
          ? props.containerPChild4MarginBottom
          : props.isPatologica && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.isJustCards && '100%'};
      margin-bottom: ${props =>
        props.isPatologica && !props.isBackground && '20px'};
    }

    @media ${device.mobile} {
      width: ${props => props.isJustCards && '100%'};
    }

    > em img {
      width: ${props => props.isPatologica && '1.5rem'};
      margin-right: ${props => props.isPatologica && '1rem'};
      transform: ${props => props.isPatologica && 'translateY(6px)'};

      @media ${device.laptop} {
        margin-right: ${props => props.isPatologica && '0.625rem'};
        transform: ${props => props.isPatologica && 'translateY(5px)'};
      }
    }

    @media ${device.mobile} {
      font-size: 0.875rem;
    }

    em {
      display: ${props => props.isPatologica && 'inline-block !important'};
      margin-bottom: ${props => props.isPatologica && '1rem'};
      color: ${props =>
        (props.isCDICardio && '#8f9194') || props.containerColorEmDesk};
      font-size: ${props => props.isCDICardio && '1rem'};
      font-style: normal;
      @media ${device.mobile} {
        margin-bottom: ${props => props.isPatologica && '10px'};
        font-size: ${props => props.isCDICardio && '0.875rem'};
      }
    }
  }

  > ul {
    list-style: ${props => (props.isOthersUnits ? 'normal' : 'none')};
    margin: ${props => (props.isShort ? '0' : '0 0 1.875rem 0')};
    padding: 0;

    @media ${device.mobile} {
      margin: ${props =>
        props.isShort ? '0' : props.isImunization ? '0' : '0 0 1.875rem 0'};
    }
  }

  ul > li {
    font-size: 1rem;
    line-height: ${props =>
      props.ulLineHeight ? props.ulLineHeight : '2.25rem'};
    color: #231f20;
    letter-spacing: 0px;
    margin: ${props => (props.isOthersUnits ? '0 0 0 1.25rem' : '0')};
    list-style-type: ${props => (props.withoutBullets ? 'none' : 'disc')};
    margin-left: ${props => (props.withoutBullets ? '0' : '20px')};

    &::marker {
      color: black;
    }

    > a {
      margin-left: 1px;
    }

    @media ${device.mobile} {
      font-size: 0.875rem;
      margin-left: ${props => (props.isRemoveMarginLi ? '0' : '20px')};
    }
  }

  ${props =>
    props.customBlockquote &&
    css`
      blockquote {
        font-size: 0.875rem;
        padding: 1.25rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        border-radius: 0.5rem;
        background: #ef51630d;
        margin: 0;

        strong {
          font-weight: 600;
        }
      }
    `}
`;

const TextSection = props => (
  <Container {...props}>
    <ReactMarkdown
      children={props.markdown}
      customBlockquote={props.customBlockquote}
    />
  </Container>
);

export default TextSection;
